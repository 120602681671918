import { render, staticRenderFns } from "./UserReport.vue?vue&type=template&id=de011234&scoped=true&"
import script from "./UserReport.vue?vue&type=script&lang=ts&"
export * from "./UserReport.vue?vue&type=script&lang=ts&"
import style0 from "./UserReport.vue?vue&type=style&index=0&id=de011234&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de011234",
  null
  
)

export default component.exports