











































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Crop, CropVariety } from "@/store/models/cropVariety";
import { AllocationListFilter } from "@/store/models/dsad";
import { Demand, Distribution } from "@/store/models/DemandAndSupply";
import {
  CropVarietyStore,
  CropStore,
  DemandSupplyStore,
  DSADStore,
  MetaStore
} from "@/store/modules";
import { MetaInfo } from "vue-meta";
import { District } from "../../../store/models/meta";

@Component({
  components: {},
  metaInfo(): MetaInfo {
    return {
      title: "Unsold Allocation Report Details",
      meta: [
        { name: "keyword", content: "Sqcc allocation list" },
        {
          name: "description",
          content: "List of Allocation of seeds",
          vmid: "demandList"
        }
      ]
    };
  }
})
export default class SCMList extends Vue {
  page: number = 1;
  pageNum: number = 1;
  language: string = "";
  loading: boolean = false;
  fiscalyearid: number | null = null;
  filterDistricts: District[] = [] as District[];
  demandcompanyid: number | null = null;
  // unsoldDemandDetail: Distribution = {} as Distribution;
  urlquery: string = "";

  routerReplacer(page?: number) {
    let query: any = {};
    // if (subPage != null) {
    //   query["subPage"] = String(subPage);
    // }
    console.log(page);
    if (this.demandcompanyid != null && this.demandcompanyid != 0) {
      query["demandcompanyid"] = String(this.demandcompanyid);
    }

    if (this.fiscalyearid != null && this.fiscalyearid != 0) {
      query["fiscalyearid"] = String(this.fiscalyearid);
    }

    if (page != null && page != 0) {
      console.log(page);
      query["page"] = String(page);
    }
    console.log(query);

    if (this.$route.name) {
      this.$router
        .replace({
          name: this.$route.name,
          query: query
        })
        .catch(error => {});
    }
    return query;
  }

  async handlePageChange(val: number) {
    this.pageNum = val;
    this.loading = true;
    let query = this.routerReplacer(this.pageNum);
    await DemandSupplyStore.unsoldAllocationReportDetail(query);
    this.loading = false;
  }

  get unsoldDetails() {
    return DemandSupplyStore.UnsoldViewDetail;
  }

  // get unsoldAllocationList() {
  //   let unsoldAllocation = DemandSupplyStore.UnsoldDetailsPaginated;
  //   let unsoldAllocationResults = unsoldAllocation.results;

  //   this.unsoldallocationlist = unsoldAllocationResults.filter(
  //     x => x.demandcompanyid === Number(this.demandcompanyid)
  //   )[0];
  //   return this.unsoldallocationlist;
  // }

  get unsoldDemandDetail() {
    return DemandSupplyStore.UnsoldDemandDetail;
  }

  private async created() {
    this.language = this.$i18n.locale;

    // if (this.$route.query.subPage) {
    //   this.pageNum = Number(this.$route.query.subPage);
    // }

    if (this.$route.query.demandcompanyid) {
      this.demandcompanyid = Number(this.$route.query.demandcompanyid);
    }
    if (this.$route.query.fiscalyearid) {
      this.fiscalyearid = Number(this.$route.query.fiscalyearid);
    }
    if (this.$route.query.page) {
      this.page = Number(this.$route.query.page);
    }

    this.loading = true;
    let query = this.routerReplacer();
    await DemandSupplyStore.unsoldAllocationReportDetail(query);
    let params = {
      demandcompanyid: this.demandcompanyid,
      fiscalyearid: this.fiscalyearid
    };
    await DemandSupplyStore.unsoldAllocationDemandDetail(params);
    this.loading = false;
    // this.unsoldAllocationList;
  }
}
