

























































































































































































import { Button } from "element-ui";
import { Vue, Component } from "vue-property-decorator";
import {
  CropStore,
  MetaStore,
  LoadStore,
  DemandSupplyStore,
  CropVarietyStore,
  JWTStore,
  UserStore
} from "@/store/modules";
import { roleChecker } from "@/utils/jwt";
import { nepaliUnicodeNumber } from "@/store/modules/dateConverter";

@Component({
  metaInfo: {
    title: "Municipality Wise Allocaition Report",
    meta: [
      { name: "keyword", content: "allocation,page" },
      {
        name: "description",
        content: "Allocation Selection Page",
        vmid: "allocation"
      }
    ]
  }
})
export default class ChooseAllocation extends Vue {
  loading: boolean = true;
  generating: boolean = false;

  fiscalYearId: number | null = null;
  cropId: number | null = null;
  seedTypeId: number | null = null;
  demandCompanyUserId: number | null = null;
  districtId: number | null = null;
  pageNum: number = 1;
  role: string = "";
  sortBy: string = "company_name";
  cropVarietyId: number | null = null;

  selectedProvince: number | null = null;
  selectedDistrict: number | null = null;

  sortByOptions = [
    { id: "company_name", name: this.$t("organizationName") },
    { id: "variety", name: this.$tc("variety") }
  ];

  get provinces() {
    return MetaStore.Province;
  }

  get districts() {
    let district = MetaStore.District;
    if (this.selectedProvince) {
      district = district.filter(x => x.provinceid == this.selectedProvince);
    }
    return district;
  }

  get userDetail() {
    return UserStore.UserDetail;
  }

  get fiscalyears() {
    return MetaStore.FiscalYear;
  }

  get demandCompanies() {
    let demandCompanies = UserStore.AllApprovedISCUsers;
    if (this.selectedProvince) {
      demandCompanies = demandCompanies.filter(x => x.provinceid == this.selectedProvince);
    }
    if (this.selectedDistrict) {
      demandCompanies = demandCompanies.filter(x => x.districtid == this.selectedDistrict);
    }
    return demandCompanies;
  }

  get crops() {
    let crops = DemandSupplyStore.FyWiseSubsidyCrops.map(x => {
      return { fiscal_year: x.fiscal_year, name: x.crop_name, id: x.crop_type };
    });
    if (this.fiscalYearId) {
      crops = crops.filter(x => x.fiscal_year == this.fiscalYearId);
      return crops;
    }
    return [];
  }

  get cropVarieties() {
    let cropVarieties = DemandSupplyStore.FyWiseSubsidy.map(x => {
      return {
        fiscal_year: x.fiscal_year,
        crop_type: x.crop_type,
        name: x.variety_name,
        id: x.crop_variety
      };
    });
    if (this.fiscalYearId || this.cropId) {
      if (this.fiscalYearId) {
        cropVarieties = cropVarieties.filter(x => x.fiscal_year == this.fiscalYearId);
      }
      if (this.cropId) {
        cropVarieties = cropVarieties.filter(x => x.crop_type == this.cropId);
      }
    }
    // Returns only unique variety
    cropVarieties = [...new Map(cropVarieties.map(item => [item["id"], item])).values()];
    return cropVarieties;
  }

  get seedTypes() {
    let seedTypes = DemandSupplyStore.SeedTypeList;
    if (this.roleChecker(["SSSMAdmin"])) {
      seedTypes = seedTypes.filter(x => [2, 3].includes(x.id));
      // seedTypes.push({ id: "both" as any, name: "Both" });
    } else if (this.roleChecker(["DOADViewer"])) {
      seedTypes = seedTypes.filter(x => [2, 3, 4].includes(x.id));
    }
    return seedTypes;
  }

  changeProvince() {
    this.selectedDistrict = null;
  }

  clearProvince() {
    this.selectedProvince = null;
    this.selectedDistrict = null;
  }

  nepaliUnicodeNumber(value: any) {
    if (this.$i18n.locale == "ne" && value) {
      return nepaliUnicodeNumber(value);
    }
    return value;
  }

  roleChecker(roles: string[]) {
    return roleChecker(roles);
  }

  async created() {
    this.role = JWTStore.role;
    this.loading = true;

    MetaStore.getAllProvince();
    MetaStore.getAllDistrict();
    UserStore.getAllApprovedISCUserList({ pageSize: "none" });

    MetaStore.getAllFiscalYear();
    DemandSupplyStore.getFyWiseSubsidy({ lang: this.$i18n.locale });
    DemandSupplyStore.getFyWiseSubsidyCrops({ lang: this.$i18n.locale });
    DemandSupplyStore.getAllSeedType();
    this.loading = false;
  }

  async download() {
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      // if role is ISMAdmin (Improved Seed Manager Admin), then pass seedTypeId = 4 (id of seedtype 'Improved Seed' )
      if (roleChecker(["ISMAdmin", "ISSMAdmin", "DLAO", "DOADViewer", "MoALD"])) {
        this.seedTypeId = 4;
      } else if (roleChecker(["improved_seed_consumer"])) {
        this.seedTypeId = 4;
        this.demandCompanyUserId = this.userDetail.id;
      }

      if (
        this.fiscalYearId != null &&
        this.cropId != null &&
        this.seedTypeId != null &&
        this.demandCompanyUserId != null
      ) {
        this.generating = true;
        let error: boolean = false;
        let report = await DemandSupplyStore.getMunicipalityWiseAllocationReport({
          fiscal_year: this.fiscalYearId,
          crop_type: this.cropId,
          seed_type: this.seedTypeId,
          demand_company_user: this.demandCompanyUserId
        }).catch(res => {
          error = true;
        });
        if (report !== undefined && report.url && error == false) {
          var link = document.createElement("a");
          link.setAttribute("href", report.url);
          link.setAttribute("download", report.name);
          link.click();
        } else {
          this.$notify.error("Error Generating report!!! Please try again");
        }
        this.generating = false;
      }
    }
  }
}
