


































































import { Vue, Component } from "vue-property-decorator";
import {
  CropStore,
  MetaStore,
  LoadStore,
  DemandSupplyStore,
  CropVarietyStore,
  JWTStore,
  ReportStore
} from "@/store/modules";
import { nepaliUnicodeNumber } from "@/store/modules/dateConverter";
import { roleChecker } from "@/utils/jwt";

@Component({
  metaInfo: {
    title: "Balance Sheet",
    meta: [
      { name: "keyword", content: "allocation,page" },
      {
        name: "description",
        content: "Allocation Selection Page",
        vmid: "allocation"
      }
    ]
  }
})
export default class ChooseAllocation extends Vue {
  loading: boolean = true;
  generating: boolean = false;

  fiscalYearId: number | null = null;
  cropId: number | null = null;
  seedTypeId: number | null = null;
  districtId: number | null = null;
  pageNum: number = 1;
  role: string = "";

  get districts() {
    return MetaStore.District;
  }

  get fiscalyears() {
    return MetaStore.FiscalYear;
  }

  get crops() {
    let crops = DemandSupplyStore.FyWiseSubsidyCrops.map(x => {
      return { fiscal_year: x.fiscal_year, name: x.crop_name, id: x.crop_type };
    });
    if (this.fiscalYearId) {
      crops = crops.filter(x => x.fiscal_year == this.fiscalYearId);
      return crops;
    }
    return [];
  }

  get cropVarieties() {
    let varieties = CropVarietyStore.CropVarieties;
    if (this.cropId && this.cropId !== null) {
      varieties = varieties.filter(x => x.croptypeid == this.cropId);
    } else {
      varieties = [];
    }
    return varieties;
  }

  get seedTypes() {
    let seedTypes = DemandSupplyStore.SeedTypeList;
    if (this.roleChecker(["SSSMAdmin"])) {
      seedTypes = seedTypes.filter(x => [2, 3].includes(x.id));
      // seedTypes.push({ id: "both" as any, name: "Both" });
    } else if (this.roleChecker(["DOADViewer", "DLAO"])) {
      seedTypes = seedTypes.filter(x => [2, 3, 4].includes(x.id));
    }
    return seedTypes;
  }

  roleChecker(roles: string[]) {
    return roleChecker(roles);
  }

  nepaliUnicodeNumber(value: any) {
    if (this.$i18n.locale == "ne" && value) {
      return nepaliUnicodeNumber(value);
    }
    return value;
  }

  private async created() {
    this.role = JWTStore.role;
    this.loading = true;
    await MetaStore.getAllFiscalYear();
    await DemandSupplyStore.getFyWiseSubsidyCrops({ lang: this.$i18n.locale });
    await DemandSupplyStore.getAllSeedType();
    this.loading = false;
  }

  async download() {
    // if role is ISMAdmin (Improved Seed Manager Admin), then pass seedTypeId = 4 (id of seedtype 'Improved Seed' )
    if (this.role == "ISSMAdmin") {
      this.seedTypeId = 4;
    }
    if (this.fiscalYearId != null && this.cropId != null && this.seedTypeId != null) {
      this.generating = true;
      let error: boolean = false;
      const report = await ReportStore.getSupplyVsDemandReport({
        fiscalYearId: this.fiscalYearId,
        cropTypeId: this.cropId,
        seedTypeId: this.seedTypeId
      }).catch(res => {
        error = true;
      });
      if (report !== undefined && error == false && !report.message) {
        const href = URL.createObjectURL(report);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "Supply & Demand Report.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      } else {
        this.$notify.error("Error Generating report!!! Please try again");
      }
      this.generating = false;
    }
  }
}
