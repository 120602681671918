
























































































































import { Button } from "element-ui";
import { Vue, Component } from "vue-property-decorator";
import {
  CropStore,
  MetaStore,
  LoadStore,
  DemandSupplyStore,
  CropVarietyStore,
  JWTStore
} from "@/store/modules";
import { roleChecker } from "@/utils/jwt";
import { nepaliUnicodeNumber } from "@/store/modules/dateConverter";

@Component({
  metaInfo: {
    title: "Subsidy Report",
    meta: [
      { name: "keyword", content: "allocation,page" },
      {
        name: "description",
        content: "Allocation Selection Page",
        vmid: "allocation"
      }
    ]
  }
})
export default class ChooseAllocation extends Vue {
  loading: boolean = true;
  generating: boolean = false;

  fiscalYearId: number | null = null;
  cropId: number | null = null;
  seedTypeId: number | null = null;
  districtId: number | null = null;
  pageNum: number = 1;
  role: string = "";
  sortBy: string = "company_name";
  cropVarietyId: number | null = null;

  sortByOptions = [
    { id: "company_name", name: this.$t("organizationName") },
    { id: "variety", name: this.$tc("variety") }
  ];

  get districts() {
    return MetaStore.District;
  }

  get fiscalyears() {
    return MetaStore.FiscalYear;
  }

  get crops() {
    let crops = DemandSupplyStore.FyWiseSubsidyCrops.map(x => {
      return { fiscal_year: x.fiscal_year, name: x.crop_name, id: x.crop_type };
    });
    if (this.fiscalYearId) {
      crops = crops.filter(x => x.fiscal_year == this.fiscalYearId);
      return crops;
    }
    return [];
  }

  get cropVarieties() {
    let cropVarieties = DemandSupplyStore.FyWiseSubsidy.map(x => {
      return {
        fiscal_year: x.fiscal_year,
        crop_type: x.crop_type,
        name: x.variety_name,
        id: x.crop_variety
      };
    });
    if (this.fiscalYearId || this.cropId) {
      if (this.fiscalYearId) {
        cropVarieties = cropVarieties.filter(x => x.fiscal_year == this.fiscalYearId);
      }
      if (this.cropId) {
        cropVarieties = cropVarieties.filter(x => x.crop_type == this.cropId);
      }
    }
    // Returns only unique variety
    cropVarieties = [...new Map(cropVarieties.map(item => [item["id"], item])).values()];
    return cropVarieties;
  }

  get seedTypes() {
    let seedTypes = DemandSupplyStore.SeedTypeList;
    if (this.roleChecker(["SSSMAdmin"])) {
      seedTypes = seedTypes.filter(x => [2, 3].includes(x.id));
      // seedTypes.push({ id: "both" as any, name: "Both" });
    } else if (this.roleChecker(["DOADViewer"])) {
      seedTypes = seedTypes.filter(x => [2, 3, 4].includes(x.id));
    }
    return seedTypes;
  }

  nepaliUnicodeNumber(value: any) {
    if (this.$i18n.locale == "ne" && value) {
      return nepaliUnicodeNumber(value);
    }
    return value;
  }

  roleChecker(roles: string[]) {
    return roleChecker(roles);
  }

  async created() {
    this.role = JWTStore.role;
    this.loading = true;
    MetaStore.getAllDistrict();
    MetaStore.getAllFiscalYear();
    DemandSupplyStore.getFyWiseSubsidy({ lang: this.$i18n.locale });
    DemandSupplyStore.getFyWiseSubsidyCrops({ lang: this.$i18n.locale });
    CropVarietyStore.getAllCropVariety();
    DemandSupplyStore.getAllSeedType();
    this.loading = false;
  }

  async download() {
    // if role is ISMAdmin (Improved Seed Manager Admin), then pass seedTypeId = 4 (id of seedtype 'Improved Seed' )
    if (roleChecker(["ISMAdmin", "ISSMAdmin", "DLAO", "DOADViewer", "MoALD"])) {
      this.seedTypeId = 4;
    }

    if (this.fiscalYearId != null && this.cropId != null && this.seedTypeId != null) {
      this.generating = true;
      let error: boolean = false;
      let report = await DemandSupplyStore.getSupplyReport({
        fiscal_year: this.fiscalYearId,
        crop_type: this.cropId,
        seed_type: this.seedTypeId,
        crop_variety: this.cropVarietyId,
        sort_by: this.sortBy
      }).catch(res => {
        error = true;
      });
      if (report !== undefined && report.report && error == false) {
        var link = document.createElement("a");
        link.setAttribute("href", report.report);
        link.click();
      } else {
        this.$notify.error("Error Generating report!!! Please try again");
      }
      this.generating = false;
    }
  }
}
