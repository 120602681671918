




































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { JWTStore } from "../../../store/modules";
import { roleChecker } from "@/utils/jwt";

@Component({
  metaInfo: {
    title: "Admin",
    meta: [{ name: "description", content: "Admin Panel", vmid: "admin" }]
  }
})
export default class Adminmenu extends Vue {
  get role() {
    return JWTStore.role;
  }

  roleChecker(roles: string[]) {
    return roleChecker(roles);
  }
}
