



















































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Crop, CropVariety } from "@/store/models/cropVariety";
import { AllocationListFilter } from "@/store/models/dsad";
import { Demand } from "@/store/models/DemandAndSupply";
import {
  CropVarietyStore,
  CropStore,
  DemandSupplyStore,
  DSADStore,
  MetaStore
} from "@/store/modules";
import { MetaInfo } from "vue-meta";
import { District } from "../../../store/models/meta";

@Component({
  components: {},
  metaInfo(): MetaInfo {
    return {
      title: "Unsold Allocation Report",
      meta: [
        { name: "keyword", content: "Sqcc allocation list" },
        {
          name: "description",
          content: "List of Allocation of seeds",
          vmid: "demandList"
        },
      ]
    };
  }
})
export default class SCMList extends Vue {
  pageNum: number = 1;
  language: string = "";
  loading: boolean = false;
  provinceid: number | null = null;
  districtid: number | null = null;
  fiscalyearid: number | null = null;
  filterDistricts: District[] = [] as District[];
  currentFiscalYear: string = "";
  urlquery: string = "";

  async filter() {
    let query: any = {};
    // let query: any = { page: 1 };
    // this.pageNum = 1;
    // this.generating = true;
    if (this.provinceid != null) {
      query["provinceid"] = Number(this.provinceid);
    }
    if (this.districtid != null) {
      query["districtid"] = Number(this.districtid);
    }
    if (this.fiscalyearid != null) {
      query["fiscalyearid"] = Number(this.fiscalyearid);
    }
    this.routerReplacer();
    let report = await DemandSupplyStore.unsoldAllocationReport(query);
  }

  routerReplacer(page?: number) {
    let query: any = {};
    if (page != null) {
      query["page"] = String(page);
    }
    if (this.provinceid != null && this.provinceid != 0) {
      query["provinceid"] = Number(this.provinceid);
    }
    if (this.districtid != null && this.districtid != 0) {
      query["districtid"] = Number(this.districtid);
    }

    if (this.fiscalyearid != null && this.fiscalyearid != 0) {
      query["fiscalyearid"] = Number(this.fiscalyearid);
    }

    if (this.$route.name) {
      this.$router
        .replace({
          name: this.$route.name,
          query: query
        })
        .catch(error => {});
    }
    return query;
  }

  clearSearch() {
    this.pageNum = 1;
    this.provinceid = null;
    this.districtid = null;
    this.routerReplacer();
    // DSADStore.getAdminAllocation();
  }

  async handlePageChange(val: number) {
    this.pageNum = val;
    this.loading = true;
    let query = this.routerReplacer(this.pageNum);
    await DemandSupplyStore.unsoldAllocationReport(query);
    this.loading = false;
  }

  get fiscalyears() {
    return MetaStore.FiscalYear;
  }

  get provinces() {
    return MetaStore.Province;
  }

  get districts() {
    this.filterDistricts = MetaStore.District;
    if (this.provinceid) {
      this.filterDistricts = this.filterDistricts.filter(
        x => x.provinceid === Number(this.provinceid)
      );
    }
    return this.filterDistricts;
  }

  get unsoldAllocationList() {
    return DemandSupplyStore.UnsoldDetailsPaginated;
  }

  viewDetail(index: number) {
    this.urlquery = this.routerReplacer();
    let demandcompanyid = this.unsoldAllocationList.results[index]["demandcompanyid"];
    DemandSupplyStore.unsoldAllocationReportDetail({
      demandcompanyid: demandcompanyid
    });
    if (this.$route.name) {
      this.$router.push({
        name: "unsoldAllocationReportDetail",
        query: {
          demandcompanyid: String(demandcompanyid),
          fiscalyearid: String(this.fiscalyearid)
          // page: String(this.pageNum)
        }
      }).catch(error => {});
    }
  }

  private async created() {
    await MetaStore.getAllProvince();
    await MetaStore.getAllDistrict();
    await MetaStore.getAllFiscalYear();
    this.language = this.$i18n.locale;

    if (this.$route.query.page) {
      this.pageNum = Number(this.$route.query.page);
    }

    if (this.$route.query.provinceid && this.$route.query.provinceid != null) {
      this.provinceid = Number(this.$route.query.provinceid);
    }

    if (this.$route.query.districtid && this.$route.query.districtid != null) {
      this.districtid = Number(this.$route.query.districtid);
    }

    if (this.$route.query.fiscalyearid && this.$route.query.fiscalyearid != null) {
      this.fiscalyearid = Number(this.$route.query.fiscalyearid);
    }

    this.fiscalyearid = Number(this.fiscalyears[0]["id"]);

    this.loading = true;
    let query = this.routerReplacer();
    await DemandSupplyStore.unsoldAllocationReport(query);
    this.loading = false;
  }
}
