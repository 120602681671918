
































































import { Button } from "element-ui";
import { Vue, Component } from "vue-property-decorator";
import Homeslider from "@/components/UIComponents/Homeslider.vue";
import {
  CropStore,
  MetaStore,
  LoadStore,
  DemandSupplyStore,
  CropVarietyStore,
  JWTStore
} from "@/store/modules";
import Cropstat from "@/components/UIComponents/Cropstat.vue";
import Recentvarieties from "@/components/UIComponents/Recentvarieties.vue";
import Marketstat from "@/components/UIComponents/Marketstat.vue";
import { roleChecker } from "@/utils/jwt";

@Component({
  metaInfo: {
    title: "Balance Sheet",
    meta: [
      { name: "keyword", content: "allocation,page" },
      {
        name: "description",
        content: "Allocation Selection Page",
        vmid: "allocation"
      }
    ]
  }
})
export default class ChooseAllocation extends Vue {
  loading: boolean = true;
  generating: boolean = false;

  fiscalYearId: number | null = null;
  cropId: number | null = null;
  seedTypeId: number | null = null;
  districtId: number | null = null;
  pageNum: number = 1;
  role: string = "";

  get districts() {
    return MetaStore.District;
  }

  get fiscalyears() {
    return MetaStore.FiscalYear;
  }

  get crops() {
    return CropStore.CropNameList;
  }

  get cropVarieties() {
    let varieties = CropVarietyStore.CropVarieties;
    if (this.cropId && this.cropId !== null) {
      varieties = varieties.filter(x => x.croptypeid == this.cropId);
    } else {
      varieties = [];
    }
    return varieties;
  }

  get seedTypes() {
    let seedTypes = DemandSupplyStore.SeedTypeList;
    if (this.roleChecker(["SSSMAdmin"])) {
      seedTypes = seedTypes.filter(x => [2, 3].includes(x.id));
      // seedTypes.push({ id: "both" as any, name: "Both" });
    } else if (this.roleChecker(["DOADViewer", "DLAO"])) {
      seedTypes = seedTypes.filter(x => [2, 3, 4].includes(x.id));
    }
    return seedTypes;
  }

  roleChecker(roles: string[]) {
    return roleChecker(roles);
  }

  private async created() {
    this.role = JWTStore.role;
    this.loading = true;
    await MetaStore.getAllDistrict();
    await MetaStore.getAllFiscalYear();
    await CropStore.getAllCropName();
    await CropVarietyStore.getAllCropVariety();
    await DemandSupplyStore.getAllSeedType();
    this.loading = false;
  }

  async download() {
    // if role is ISMAdmin (Improved Seed Manager Admin), then pass seedTypeId = 4 (id of seedtype 'Improved Seed' )
    if (this.role == "ISSMAdmin") {
      this.seedTypeId = 4;
    }
    if (this.fiscalYearId != null && this.cropId != null && this.seedTypeId != null) {
      this.generating = true;
      let error: boolean = false;
      let report = await DemandSupplyStore.getBalanceSheetReport({
        fiscalYearId: this.fiscalYearId,
        cropTypeId: this.cropId,
        seedTypeId: this.seedTypeId,
        role: this.role
      }).catch(res => {
        error = true;
      });
      if (report !== undefined && error == false) {
        var link = document.createElement("a");
        link.setAttribute("href", report);
        link.click();
      } else {
        this.$notify.error("Error Generating report!!! Please try again");
      }
      this.generating = false;
    }
  }
}
