



















































import { Button } from "element-ui";
import { Vue, Component } from "vue-property-decorator";
import {
  CropStore,
  MetaStore,
  LoadStore,
  DemandSupplyStore,
  CropVarietyStore,
  JWTStore
} from "@/store/modules";
import { roleChecker } from "@/utils/jwt";

@Component({
  metaInfo: {
    title: "Subsidy Report",
    meta: [
      { name: "keyword", content: "allocation,page" },
      {
        name: "description",
        content: "Allocation Selection Page",
        vmid: "allocation"
      }
    ]
  }
})
export default class ChooseAllocation extends Vue {
  loading: boolean = true;
  generating: boolean = false;

  fiscalYearId: number | null = null;
  cropId: number | null = null;
  seedTypeId: number | null = null;
  districtId: number | null = null;
  pageNum: number = 1;
  role: string = "";
  sortBy: string = "company_name";
  userType: string = "improved_seed_consumer";

  sortByOptions = [
    { id: "company_name", name: "Company Name" },
    { id: "variety", name: "Variety Name" }
  ];

  userTypeOptions = [
    {
      id: "improved_seed_consumer",
      name: `${this.$t("improved")} ${this.$t("seed")} ${this.$t("consumer")}`
    }
    // { id: "improved_seed_supplier", name: "Improved Seed Supplier" }
  ];

  get districts() {
    return MetaStore.District;
  }

  get fiscalyears() {
    return MetaStore.FiscalYear;
  }

  get crops() {
    return CropStore.CropNameList;
  }

  get cropVarieties() {
    let varieties = CropVarietyStore.CropVarieties;
    if (this.cropId && this.cropId !== null) {
      varieties = varieties.filter(x => x.croptypeid == this.cropId);
    } else {
      varieties = [];
    }
    return varieties;
  }

  get seedTypes() {
    let seedTypes = DemandSupplyStore.SeedTypeList;
    if (this.roleChecker(["SSSMAdmin"])) {
      seedTypes = seedTypes.filter(x => [2, 3].includes(x.id));
      // seedTypes.push({ id: "both" as any, name: "Both" });
    }
    return seedTypes;
  }

  roleChecker(roles: string[]) {
    return roleChecker(roles);
  }

  private async created() {
    this.role = JWTStore.role;
    // this.loading = true;
    // await MetaStore.getAllDistrict();
    // await MetaStore.getAllFiscalYear();
    // await CropStore.getAllCropName();
    // await CropVarietyStore.getAllCropVariety();
    // await DemandSupplyStore.getAllSeedType();
    // this.loading = false;
  }

  async download() {
    this.generating = true;
    let error: boolean = false;
    let report = await JWTStore.getUserReport({ company_role: this.userType }).catch(res => {
      error = true;
    });
    if (report !== undefined && report.report && error == false) {
      var link = document.createElement("a");
      link.setAttribute("href", report.report);
      link.click();
    } else {
      this.$notify.error("Error Generating report!!! Please try again");
    }
    this.generating = false;
  }
}
