





































































































import { Button } from "element-ui";
import { Vue, Component } from "vue-property-decorator";
import Homeslider from "@/components/UIComponents/Homeslider.vue";
import {
  CropStore,
  MetaStore,
  LoadStore,
  DemandSupplyStore,
  CropVarietyStore,
  ReportStore
} from "@/store/modules";
import Cropstat from "@/components/UIComponents/Cropstat.vue";
import Recentvarieties from "@/components/UIComponents/Recentvarieties.vue";
import Marketstat from "@/components/UIComponents/Marketstat.vue";

@Component({
  metaInfo: {
    title: "Balance Sheet",
    meta: [
      { name: "keyword", content: "allocation,page" },
      {
        name: "description",
        content: "Allocation Selection Page",
        vmid: "allocation"
      }
    ]
  }
})
export default class ChooseAllocation extends Vue {
  loading: boolean = true;
  generating: boolean = false;

  fiscalYearId: number | null = null;
  cropCategoryId: number | null = null;
  seedTypeId: number | null = null;
  districtId: number | null = null;
  pageNum: number = 1;

  indexMethod(index: number) {
    let i = (this.dataList.page - 1) * this.dataList.page_size + index + 1;
    return i;
  }

  get dataList() {
    return ReportStore.CategoryReportPaginated;
  }

  get districts() {
    return MetaStore.District;
  }

  get fiscalyears() {
    return MetaStore.FiscalYear;
  }

  get cropCategories() {
    return CropVarietyStore.cropCategory;
  }

  get seedTypes() {
    return DemandSupplyStore.SeedTypeList;
  }

  async handlePageChange(val: number) {
    this.pageNum = val;
    this.loading = true;
    let query = this.routerReplacer();
    await ReportStore.getCategoryReport(query);
    this.loading = false;
  }

  routerReplacer(page?: number) {
    let query: any = {};
    if (page != null) {
      query["page"] = String(page);
    } else if (this.pageNum != null) {
      query["page"] = String(this.pageNum);
    }
    if (this.fiscalYearId != null) {
      query["fiscalYearId"] = String(this.fiscalYearId);
    }
    if (this.cropCategoryId != null) {
      query["cropCategoryId"] = String(this.cropCategoryId);
    }
    if (this.seedTypeId != null) {
      query["seedTypeId"] = String(this.seedTypeId);
    }
    if (this.$route.name) {
      this.$router
        .replace({
          name: this.$route.name,
          query: query
        })
        .catch(error => {});
    }
    return query;
  }

  async filter() {
    this.pageNum = 1;
    let query = this.routerReplacer();
    await ReportStore.getCategoryReport(query);
  }

  clearSearch() {
    let query: any = { page: 1 };
    this.pageNum = 1;
    this.fiscalYearId = null;
    this.cropCategoryId = null;
    this.seedTypeId = null;
    this.routerReplacer();
    ReportStore.getCategoryReport(query);
  }

  private async created() {
    if (this.$route.query.page) {
      this.pageNum = Number(this.$route.query.page);
    }
    if (this.$route.query.fiscalYearId) {
      this.fiscalYearId = Number(this.$route.query.fiscalYearId);
    }
    if (this.$route.query.cropCategoryId) {
      this.cropCategoryId = Number(this.$route.query.cropCategoryId);
    }
    if (this.$route.query.seedTypeId) {
      this.seedTypeId = Number(this.$route.query.seedTypeId);
    }
    this.loading = true;
    await MetaStore.getAllFiscalYear();
    await CropVarietyStore.getCropCategory();
    await DemandSupplyStore.getAllSeedType();
    let query = this.routerReplacer();
    await ReportStore.getCategoryReport(query);
    this.loading = false;
  }
}
