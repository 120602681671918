





































































































import { Component, Vue } from "vue-property-decorator";
import { MetaStore, ReportStore } from "@/store/modules";
import { MetaInfo } from "vue-meta";

@Component({
  metaInfo(): MetaInfo {
    return {
      title: `Transaction Report`,
      meta: [
        { name: "keyword", content: "Sqcc Transaction Report" },
        {
          name: "description",
          content: "Transaction Report",
          vmid: "report"
        }
      ]
    };
  }
})
export default class TransactionReport extends Vue {
  fiscalYearId: number | null = null;
  provinceId: number | null = null;
  get fiscalyears() {
    return MetaStore.FiscalYear;
  }

  get mobileScreen() {
    return MetaStore.mobileScreen;
  }

  get provinces() {
    return MetaStore.Province;
  }

  checkIfRequired(event: any) {
    let index = this.$validator.fields.items.findIndex(x => x.name == event);
    if (index >= 0 && this.$validator.fields.items[index].flags["required"]) {
      return true;
    }
    return false;
  }

  async downloadReport() {
    let isValid = await this.$validator.validateAll();
    if (isValid && this.fiscalYearId) {
      let error: boolean = false;
      let report = await ReportStore.getTransactionReport({
        fiscalYearId: this.fiscalYearId,
        provinceId: this.provinceId
      }).catch(res => {
        error = true;
      });
      if (report !== undefined && report.url !== undefined && error == false) {
        var link = document.createElement("a");
        link.setAttribute("href", report.url);
        link.click();
      } else {
        this.$notify.error("Error Generating report!!! Please try again");
      }
    }
  }

  created() {
    MetaStore.getAllFiscalYear();
    MetaStore.getAllProvince();
  }
}
